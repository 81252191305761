import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const importAll = (r) => r.keys().map(r);

const backgrounds = importAll(require.context('../../public/img/Backgrounds', false, /\.(png|jpe?g|svg)$/));

const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  padding: 10;
`;

const BackgroundImage = styled.img`
  width: 100%;
  cursor: pointer;
  margin-bottom: 5%;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const BackgroundSelector = ({ setBackground }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const backgroundsPerPage = 5;
  const totalPages = Math.ceil(backgrounds.length / backgroundsPerPage);

  const startIndex = currentPage * backgroundsPerPage;
  const currentBackgrounds = backgrounds.slice(startIndex, startIndex + backgroundsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div style={{ fontFamily: 'Lilita One', textAlign: "center", height: '100%' }}>
      <h3>Backgrounds</h3>
      <BackgroundContainer>
        {currentBackgrounds.map((background, index) => (
          <BackgroundImage
            key={index}
            src={background}
            alt={`Background ${index + 1}`}
            onClick={() => setBackground(background)}
          />
        ))}
        <PaginationContainer>
        <Button onClick={handlePrevPage} disabled={currentPage === 0}>
          ⇦
        </Button>
        <Button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
          ⇨
        </Button>
      </PaginationContainer>
      </BackgroundContainer>

    </div>
  );
};

export default BackgroundSelector;
